@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/toastr.js/latest/toastr.min.css');

body {
  margin: 0 !important;
  font-family: 'Montserrat',
    sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
}
iframe{
  position:unset!important;
}
#toast-container>div{
  opacity: 0.9;
  width: 500px;
}
.colorBlack{
  color: black;
}
.cursor-pointer{
  cursor: pointer;
}