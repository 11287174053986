@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto:wght@300&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  height: 100%;
}

body {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

a {
  text-decoration: none;
}

footer1 {
  background-color: #eaeaea;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%; 
  color: white;
  text-align: center; 
}

footer1 p {
  font-size: 25px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  // text-align: center;
  color: #446eac;
  margin-top: auto;
}

footer1 li {
  list-style: none;
  margin: 10px;
  display: inline-block;
  font-size: 23px;
}
footer1 li p {
  font-size: 25px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #446eac;
  padding-right: 180px;
}
.footerclass1 ul p {
  font-size: 23px;
}
.footerclass {
  display: flex;
  font-size: 10px;
  border-left: 1px solid #fff;
  text-align: center;
}
.contact_box {
  padding-top: 18px;
  // font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: left;

  display: flex;
  flex-direction: column;
  text-align: left;
}

.contact1 {
  // box-shadow: 1px 2px #a29999;
  // border: 2px solid #a29999;
  // background-color: #446eac;
  // color: #fff;
  padding: 0;
  width: unset;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  font-weight: 600;
  margin-right: 10px;
  border-radius: 0px;
  color: #446eac;
  text-align: unset;
}

.contact {
  // box-shadow: 1px 2px #a29999;
  // border: 2px solid #a29999;
  // background-color: #446eac;
  // padding: 10px 28px;
  // padding-left: 10px;
  // color: #fff;
  width: unset;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  cursor: pointer;
  margin-right: 20px;
  border-radius: 0px;
  color: #446eac;
  padding: 0;
  float:left;
  text-align: unset;
}
.icons {
  font-size: 18px;
}

.mainnew {
  border-left: 5px solid rgb(222, 231, 234);
  height: 100px;
  margin: 0 auto;
  width: 2px;
}
.footertop{
  margin-top: 10px;
  margin-bottom:0;
}
@media screen and (min-width: 480px) and (max-width:600px) {
    .contact1{
        width:unset;
        }
        .contact_box{
            align-items: center;
            justify-content: end;
            display: flex;
            flex-direction:column;
        }
        .contact{
            width:unset;
        }
        footer1 li p{
            font-size:17px;           
        }
        footer1 p{
            font-size:17px;
        }
        .footerclass li p{
            padding-left: 10px;
        }
       
}
// @media screen and (min-width:300px) and (min-height: 851px){
//     .contact1{
//         width:unset;
//         }
//         .contact_box3{
//             align-items: center;
//             justify-content: end;
//             display: flex;
//             flex-direction:column;
//         }
//         .footerclass p{
//             font-size:14px;
//         }
//         .contact{
//             width:unset;
//         }
//         footer1 li p{
//             font-size:14px;
//             padding-left:160px;
//         }
//         footer1 p{
//             font-size:12px;
//         }

// }
@media screen and (min-width: 600px) and (max-width:768px) {
    .contact1{
        width:unset;
        }
        .contact_box{
            align-items: center;
            justify-content: end;
            display: flex;
            flex-direction:column;
        }
        .footerclass p{
            font-size:14px;
        }
        .contact{
            width:unset;
        }
        footer1 li p{
            font-size:14px;
            padding-left:160px;
        }
        footer1 p{
            font-size:12px;
        }

}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px){
    .contact1{
        width:unset;
        }
        .contact_box{
            align-items: center;
            justify-content: end;
            display: flex;
            flex-direction:column;
        }
        .footerclass p{
            font-size:14px;
        }
        .contact{
            width:unset;
        }
        footer1 li p{
            font-size:14px;
            padding-left:160px;
        }
        footer1 p{
            font-size:12px;
        }
}
@media only screen and (min-width: 1030px) and (max-width: 1366px) {
    .contact1{
        width:unset;
        }
        .contact_box{
            // align-items: center;
            justify-content: end;
            // display: flex;
            // flex-direction:column;
        }
        .footerclass p{
            font-size:14px;
        }
        .contact{
            width:unset;
        }
        footer1 li p{
            font-size:14px;
            padding-left:160px;
        }
        footer1 p{
            // font-size:12px;
        }
}
@media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) { 
      .contact1{
        width:unset;
        }
        .contact_box3{
            align-items: center;
            justify-content: end;
            display: flex;
            flex-direction:column;
        }
        .footerclass p{
            font-size:14px;
        }
        .contact{
            width:unset;
        }
        footer1 li p{
            font-size:14px;
            padding-left:160px;
        }
        footer1 p{
            font-size:12px;
        }
    }
    @media only screen 
    and (device-width: 390px) 
    and (device-height: 844px) 
    and (-webkit-device-pixel-ratio: 3) {
      .contact1{
        width:unset;
        }
        .contact_box3{
            align-items: center;
            justify-content: end;
            display: flex;
            flex-direction:column;
        }
        .footerclass p{
            font-size:14px;
        }
        .contact{
            width:unset;
        }
        footer1 li p{
            font-size:14px;
            padding-left:160px;
        }
        footer1 p{
            font-size:12px;
        }

     }
     @media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) {
      .contact1{
        width:unset;
        }
        .contact_box3{
            align-items: center;
            justify-content: end;
            display: flex;
            flex-direction:column;
        }
        .footerclass p{
            font-size:14px;
        }
        .contact{
            width:unset;
        }
        footer1 li p{
            font-size:14px;
            padding-left:160px;
        }
        footer1 p{
            font-size:12px;
        }
     }
@media only screen
and (width: 390px) 
and (device-height: 844px)
and (-webkit-device-pixel-ratio: 3) { 
  .contact1{
    width:unset;
    }
    .contact_box3{
        align-items: center;
        justify-content: end;
        display: flex;
        flex-direction:column;
    }
    .footerclass p{
        font-size:14px;
    }
    .contact{
        width:unset;
    }
    footer1 li p{
        font-size:14px;
        padding-left:160px;
    }
    footer1 p{
        font-size:12px;
    }
}
// @media only screen  and (min-width: 1370px) and (max-width: 1605px)  {
//     .contact1{
//         width:unset;
//         }
//         .contact_box{
//             align-items: center;
//             justify-content: end;
//             display: flex;
//             flex-direction:column;
//         }
//         .footerclass p{
//             font-size:14px;
//         }
//         .contact{
//             width:unset;
//         }
//         footer1 li p{
//             font-size:14px;
//             padding-left:160px;
//         }
//         footer1 p{
//             font-size:12px;
//         }
// }
