.Box {
  background-color: #222a42;
  width: 30%;
  margin: auto;
  border-radius: 8px;
  padding: 10px;
}

.Text1 {
  font-size: 26px;
  line-height: 32px;
  max-width: 624px;
  font-weight: 600;
  // margin-left: 17px;
}

.main {
  display: unset;
  width: 50%;
}

.option {
  outline: 0;
  border: 1px solid gray;
  border-color: gray;
  padding: 11px;
  width: 90%;
  margin: auto;
  margin-bottom: 18px;
  font-size: 14px;
  option{

  }
}

.my-popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 50%;
  padding: 5px;
}
.my-popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].my-popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.my-popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].my-popup-overlay {
  background: transparent;
}

#popup-2{
  color: red !important;
}

#popup-root {
  .modal {
    background-color: #fff;
    border: 2px solid #222a42;
    border-radius: 10px;
    padding: 12px;
    .close {
      display: none;
    }
  }
}

.header{
  margin: 14px 0;
  margin-bottom: 30px;
}

.h4 {
  color: gray;
  font-size: 10px;
  padding: 0;
  margin: 0;
  padding-left: 161px;
}

.link {
  color: #0161c3;
  font-size: 10px;
  padding-left: 10px;
  font-weight: 800;
}

.link1 {
  color: #0161c3;
  font-size: 10px;
  padding-left: 10px;
  font-weight: 800;
  margin: auto;
}

.Text {
  color: white;
  // margin-bottom: 5px;
  // padding-left: 17.5px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 12px;
}

.subtitle {
  margin-top: 15px;
}

.inputbox1 {
  outline: 0;
  border: 1px solid gray;
  border-color: gray;
  padding: 11px;
  width: 85%;
  margin: auto;
  margin-bottom: 18px;
  font-size: 14px;
}

.submit {
  border: 2px solid #27335a;
  background-color: #27335a;
  width: 25%;
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
  padding: 4px 22px;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
}

.signup_box {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.signup {
  border: 2px solid #1b2956;
  background-color: #5c74c3;
  width: 25%;
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
  padding: 4px 22px;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  margin-right: 20px;
}

.terms_conditions{
  vertical-align: top;
  margin-right: 10px;
}

.checkbox{
  width: 22px;
  height: 22px;
}

.recaptcha{
  // display: flex;
  div{
    div{
      div{
        margin: auto;
      }
    }
  }
}

.forgetpassword{
  text-decoration: underline;
  color: #0161c3;
  margin-top: 10px;
}

.submit_title {
  color: white;
  padding: 10px;
}

::placeholder {
  padding-left: 0px;
}

.welcomeBox {
  text-align: center;
  margin: 5% 0;

  h2 {
    font-size: 32px;
  }
}

.errorMsg {
  padding: 16px;
  background-color: #d4edda;
  font-size: 14px;
  margin: 16px;
  border-radius: 4px;
  border-color: #c3e6cb;
  color: #721c24;
}

.formWrapper {
  padding: 0 1rem;
}