.Box {
  width: 40%;
  margin: auto;
  // background-color: #222a42;
  // border-radius: 8px;
  margin-top: 100px;
  margin-bottom: 30px;

}
.Box1 {
  width: 100%;
  margin: auto;
  // background-color: #222a42;
  // border-radius: 8px;  
  margin-bottom: 30px;

}

.rows{
  display: flex;
  justify-content: space-between;
}

.h2 {
  font-size: 26px;
  line-height: 32px;
  max-width: 624px;
  font-weight: 600;
  margin-left: 0; 
  margin-top: 15px;
  margin-bottom: 15px;
  color:rgb(28, 28, 50);
  padding-bottom:15px;
}

.inputbox1 {
  width: 40%;
  padding: 12px;
  border: 2px solid #504377;
  margin: auto;
  background: white;
  margin-bottom: 38px;
  color: black;
  // border-radius: 8px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  outline: 0; 
}
.inputbox2 {
  width: 100%;
  padding: 12px;
  border: 2px solid #e8e7ec;
  background: white;
  margin-bottom: 38px;
  color: black;
  // border-radius: 8px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  outline: 0; 
}
.inputbox3 {
  width: 100%;
  padding: 16px;
  border: 2px solid #e8e7ec;
  background: white;
  margin-bottom: 38px;
  color: black;
  // border-radius: 8px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  outline: 0; 
}
select.inputbox1 {
  width: 40%;
}

.Text {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.1px;
  margin-left: 31px;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;
  color: rgb(28, 28, 50);
  
}
.Text1 {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.1px;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;
  color: rgb(28, 28, 50);
  width:100%;
  font-weight: 600;  
}
.Text2 {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.1px;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;
  color: rgb(28, 28, 50);
  
}
.Text3{
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.1px;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;
  color: rgb(28, 28, 50);
}
.submit {
  border: 2px solid #27335a;
  background-color: #27335a;
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
  padding: 10px 22px;
  font-size: 18px;
  line-height: 24px;  
  border-radius: 10px; 
}
.submit1 {
  border: 2px solid #27335a;
  background-color: #27335a;
  margin-top: 10px;
  margin-bottom: 10px;
  color: rgb(246, 241, 241);
  padding: 10px 22px;
  font-size: 18px;
  line-height: 24px;  
}
.head1{
    padding: 10px;
}
.Active {
  justify-content: space-between;
  margin-bottom: 5px;
}

.Role {
  margin-bottom: 5px;
}

.role1 {
  margin-left: 50px;
}

.role2 {
  margin-left: 10px;
}

.active1 {
  margin-left: 50px;
}

.active2 {
  margin-left: 10px;
}

.radio-group {
  >div {
    div {
      label {
        color: white !important;
        //cursor: not-allowed !important;
        opacity: 1 !important;

        div div:hover {
          border: solid 2px rgba(255, 255, 255, 0.5) !important;
        }
      }
    }
  }
}

.radio {
  margin-left: 20px;
  border-bottom: none;
}

.button {

  margin-left: 31px;
  margin-bottom: 35px;
}

.heading {
  position: absolute;
  left: 48px;
  top: 100px;
  font-size: 24px;
  margin-top: 24px;
  margin-left: 48px;
  font-size: 26px;
  line-height: 32px;
  max-width: 624px;
  font-weight: 600;
}
.TextNew{
  padding:10px;
}

.loadingText {
  text-align: center;
  margin: 10% 0;
}

.errorMsg {
  padding: 16px;
  background-color: #d4edda;
  font-size: 14px;
  margin: 16px;
  border-radius: 4px;
  border-color: #c3e6cb;
  color: #721c24;
}
 
.head1{
  padding:10px;
}

  button {
    border: 0;
    padding: 10px 20px;
    background-color: transparent;
    border-radius: 10px;  
    color: #fff;    
  }
  

  
  .buttonNew{
    position: relative;
    display: inline-block;
    background-color: #27335a;
    border-radius: 10px; 
    margin-left:8px;    
    margin-top: 10px;
    margin-bottom: 10px;
    color: white;
    font-size: 18px;
    line-height: 24px;  
    border-radius: 10px; 
  }
  
    
  .buttonNew input[type="file"] {
    position: absolute;   
    cursor: pointer;
    top: 0;
    right: 0;
    opacity: 0;
    padding-top:10px;
  }
  .main1{    
    padding-top: 35px;
  }

