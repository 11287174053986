.search-wrapper {
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      margin-left: 40px;}
    input {
      background: none;
      color: white;
      height: 100%;
      padding-left: 20px;
      width: 90%;
      font-weight: normal;
      &::placeholder {
        color: white;
        font-style: italic;
      }
    }
    .filter-icon {
      margin-left: 0;
      margin-right: 0;
    }
  }
  button {
    padding-left: 20px;
    padding-right: 20px;
    svg {
      margin-right: 0px;
    }
  }
}

.filter-select-wrapper {
  margin: auto 15px;
  > div {
    background: none;
    height: 37px;
  }
  button {
    outline: none;
    border-radius: inherit;
    div {
      background: none;
      height: 37px;
      &:nth-child(2) {
        justify-content: center;
        margin: 0;
        svg {
          fill: white;
          stroke: white;
        }
      }
    }
    input {
      background: none;
      color: white;
      height: 37px;
      font-size: 15px;
      &::placeholder {
        color: white;
        font-style: italic;
        font-weight: normal;
      }
    }
  }
}

.filter-drop-wrapper {
  margin: auto 15px;
  button {
    outline: none;
    border-radius: inherit;
    margin: auto;
    width: 100%;
    padding: 0 20px;
    height: 100%;
    .italic {
      font-style: italic;
    }
  }
}

.filter-input {
  width: 90%;
}