.download {
  cursor: pointer !important;
  svg {
    margin: auto !important;
  }
}

.search {
  background: none !important;
  color: white !important;
  font-size: 13px !important;
  height: 30px !important;
  padding: 0 !important;
}

.table-wrapper {
  overflow-y: auto !important;
  white-space:nowrap;
  &::-webkit-scrollbar {
    width: 8px !important;
    background-color: #141935 !important;
    border-radius: 30px !important;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 30px !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    background-color: #343456 !important;
  }
  table {
    min-width: 100% !important;
    thead {
      th {
        border: 0 !important;
        padding-left: 1px;
      }
      .quality-th {
        width: 70px !important;
      }
    }
    tbody td {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
  }
  .table-button {
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
  }
}

.graph-wrapper {
  position: relative !important;
  .graph-select {
    position: absolute !important;
    right: 10px !important;
    top: 10px !important;
    width: 200px !important;
    &.small-screen * {
      height: 30px !important;
    }
  }
  .graph-img {
    object-fit: unset !important;
  }
}

.ask-bid-btn {
  text-align: center !important;
}

.ask-price {
  border-right: 1px solid white !important;
  padding-right: 20px !important;
  width: fit-content !important;
  &.small-screen {
    border-right: none !important;
    padding-right: 0 !important;
    height: 80px !important;
  }
}

.ask-form-field {
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  label {
    color: #A9A9A9 !important;
    font-size: 13px !important;
  }
  &.other-screen div {
    width: 170px !important;
  }
  &.small-screen div {
    width: 80px !important;
  }
}

.ask-small-screen button,
.ask-small-screen button div,
.ask-small-screen button div input {
  height: 30px !important;
}

.bid-input {
  height: 47px !important;
  &.small-screen {
    height: 30px !important;
  }
}

.price-input {
  position: relative !important;
  width: 100% !important;
  > span {
    position: absolute !important;
    color: #444444 !important;
    font-weight: 600 !important;
    top: 4px !important;
    right: 20px !important;
  }
}

.sell-buttons {
  text-align: center !important;
}

.offers {
  overflow: auto !important;
  &::-webkit-scrollbar {
    width: 8px !important;
    background-color: #141935 !important;
    border-radius: 30px !important;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 30px !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    background-color: #343456 !important;
  }
}

.filter-sales {
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: inherit;
  }
}

.headerBox{
  min-height: 30px;
}

.Heading{
  font-size: 22px;
}
