.BoxAuditLog {
  width: 100%;
  // background-color: #222a42;
  // margin: auto;
}

.date {
  margin: 30px;
  width: 40%;
}

.datebox {
  display: flex;
}

.react-datepicker__input-container {
  width: 20% !important;
}
.table_box{
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(44, 41, 41, 0.15);
  color: #d6e5f4; 
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.table_head{
  background-color:#e9edf4;
  color:rgb(48, 45, 45);
}

.button {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  border-radius: 0;
  background-color: #27335a;
  color: white;
  border: #27335a;
  width: 10%;
  margin-left: 30px;
  padding: 8px;
  // padding-left: 25px;
  margin-bottom: 20px;
  margin-right: 700px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.data {
  font-size: 30px;
  margin: auto;
}

.isLoading {
  text-align: center;
  padding: 5%;
  color: #29332a;
}
.pagination {
  display: flex;
  justify-content: center;
  color: #fff;
  background-color:#27335a;
}

.pagination a {
  color: #eef3ee;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  border: 1px solid #eaebf3;
}

.pagination a.active {
  background-color: #e0e3f0;
  color: rgb(170, 156, 156);
  border: 1px solid #29332a;
}

.pagination a:hover:not(.active) {
  background-color: rgb(135, 161, 169);
  color: #e5e6ee;
}

.pagination a:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination a:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.footer {
  background-color: white;
}


.inputboxaudit {
  width: 100%;
  padding: 6px;
  border: 2px solid #e8e7ec;
  background: white;
  margin-bottom: 38px;
  color: black;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  outline: 0;
}
.basic{
  font-size:14px;
}
.auditComment{
  white-space: break-spaces;
}