.Box {
  background-color: #222a42;
  width: 30%;
  margin: auto;
  border-radius: 8px;
  padding: 10px;
}

.Text1 {
  font-size: 26px;
  line-height: 32px;
  max-width: 624px;
  font-weight: 600;
  // margin-left: 17px;
}

.main {
  display: flex;
}

.h4 {
  color: gray;
  font-size: 10px;
  padding: 0;
  margin: 0;
  padding-left: 161px;
}

.link {
  color: #0161c3;
  font-size: 10px;
  padding-left: 10px;
  font-weight: 800;
}

.link1 {
  color: #0161c3;
  font-size: 10px;
  padding-left: 10px;
  font-weight: 800;
  margin: auto;
}

.Text {
  color: white;
  // margin-bottom: 5px;
  // padding-left: 17.5px;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 12px;
}
.subtitle {
  margin-top: 15px;
}
.inputbox1 {
  outline: 0;
  border: 1px solid gray;
  border-color: gray;
  padding: 11px;
  width: 86%;
  margin: auto;
  margin-bottom: 18px;
  font-size: 14px;
  color: black;
}

.submit {
  border: 2px solid #27335a;
  background-color: #27335a;
  width: 35%;
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
  padding: 8px 22px;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  margin-right: 20px;
}

.signup_box {
  display: flex;
  flex-direction: row !important;
}

.signup {
  border: 2px solid #1b2956;
  background-color: #5c74c3;
  width: 35%;
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
  padding: 8px 22px;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
}

.forgetpassword {
  text-decoration: underline;
  color: #0161c3;
  margin-top: 5px;
  cursor: pointer;
}

.submit_title {
  color: white;
  padding: 10px;
}

::placeholder {
  padding-left: 0px;
}

.welcomeBox {
  text-align: center;
  margin: 5% 0;
  h2 {
    font-size: 32px;
  }
}
.errorMsg {
  padding: 16px;
  background-color: #d4edda;
  font-size: 14px;
  margin: 16px;
  border-radius: 4px;
  border-color: #c3e6cb;
  color: #721c24;
  display: flex;
  align-items: center;
}
.formWrapper {
  padding: 0 1rem;
  width: 100%;
}
.showErrorToast {
  display: flex;
  justify-content: center;
  color: blue;
}
@media screen and (min-width: 480px) and (max-width: 600px) {
  .Box {
    width: 60%;
  }
  h1 {
    font-size: 15px;
  }
  .subtitle {
    font-size: 10px;
  }
  .Text1 {
    font-size: 15px;
  }
  .Text {
    font-size: 12px;
  }
  .formWrapper {
    width: 100%;
    height: 80%;
  }
  .signup_box {
    flex-direction: column;
    display: flex;
  }
  .submit {
    width: 50%;
    font-size: 10px;
    flex-direction: column;
    padding: 5px 16px;
  }
  .signup {
    font-size: 10px;
    width: 50%;
    padding: 5px 16px;
  }
  .forgetpassword {
    font-size: 8px;
  }
  .inputbox1 {
    height: 8%;
    font-size: 7px;
  }
}
// @media screen and (min-width: 300px) and (min-height: 851px) {
//   h1 {
//     font-size: 14px;
//   }
//   .h3 {
//     font-size: 12px;
//   }
//   .subtitle {
//     font-size: 6px;
//   }
//   .Box {
//     width: 60%;
//   }
//   .Text1 {
//     font-size: 7px;
//   }
//   .Text {
//     font-size: 7px;
//   }
//   .formWrapper {
//     width: 100%;
//     height: 80%;
//   }
//   .signup_box {
//     flex-direction: column;
//   }
//   .submit {
//     width: 80%;
//     font-size: 10px;
//     flex-direction: column;
//     padding: 2px 10px;
//   }
//   .signup {
//     font-size: 10px;
//     width: 80%;
//     padding: 2px 10px;
//   }
//   .forgetpassword {
//     font-size: 5px;
//   }
//   .inputbox1 {
//     height: 8%;
//     font-size: 4px;
//   }
// }
@media screen and (min-width: 600px) and (max-width: 768px) {
  h1 {
    font-size: 20px;
  }
  .Box {
    width: 75%;
  }
  .subtitle {
    font-size: 12px;
  }
  .Text1 {
    font-size: 17px;
  }
  .Text {
    font-size: 14px;
  }
  .formWrapper {
    width: 100%;
    height: 80%;
  }
  .signup_box {
    flex-direction: column;
  }
  .submit {
    width: 80%;
    font-size: 14px;
    flex-direction: column;
    padding: 5px 16px;
  }
  .signup {
    font-size: 14px;
    width: 80%;
    padding: 5px 16px;
  }
  .forgetpassword {
    font-size: 10px;
  }
  .inputbox1 {
    height: 8%;
    font-size: 10px;
  }
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  h1 {
    font-size: 20px;
  }
  .Box {
    width: 75%;
  }
  .subtitle {
    font-size: 12px;
  }
  .Text1 {
    font-size: 17px;
  }
  .Text {
    font-size: 14px;
  }
  .formWrapper {
    width: 100%;
    height: 80%;
  }
  .signup_box {
    flex-direction: column;
  }
  .submit {
    width: 80%;
    font-size: 14px;
    flex-direction: column;
    padding: 5px 16px;
  }
  .signup {
    font-size: 14px;
    width: 80%;
    padding: 5px 16px;
  }
  .forgetpassword {
    font-size: 10px;
  }
  .inputbox1 {
    height: 8%;
    font-size: 10px;
  }
}
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  h1 {
    font-size: 20px;
  }
  .Box {
    width: 75%;
  }
  .subtitle {
    font-size: 12px;
  }
  .Text1 {
    font-size: 17px;
  }
  .Text {
    font-size: 14px;
  }
  .formWrapper {
    width: 100%;
    height: 80%;
  }
  .signup_box {
    flex-direction: column;
  }
  .submit {
    width: 80%;
    font-size: 14px;
    flex-direction: column;
    padding: 5px 16px;
  }
  .signup {
    font-size: 14px;
    width: 80%;
    padding: 5px 16px;
  }
  .forgetpassword {
    font-size: 10px;
  }
  .inputbox1 {
    height: 8%;
    font-size: 10px;
  }
}
