.radio-group {
  > div {
    div {
      label {
        color: white !important;
        opacity: 1 !important;
        div div:hover {
          border: solid 2px rgba(255,255,255,0.5) !important;
        }
      }
    }
  }
}

.new-cap-page{
  label{
    margin-left: unset;
  }
}