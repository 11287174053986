.box {
  border-collapse: collapse;
  margin: 14px 0;
  font-size: 0.9em; 
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  color: #d6e5f4;
  background-color: #222a42;
}

 .table_box {
align-items: center;
 }

table {
  font-family: 'Montserrat', sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.tableborder {
  border-bottom: 1px solid #504377;
}

table td {
  border-bottom: 0 !important;
}

.table_head {
  text-align: left;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  border-bottom: 0;
  height: 50px;
}

.button {
  color: #fff;
  padding: 14px;
  min-width: 130px;
  text-align: center;
  cursor: pointer;
  background-color: #222a42;
  margin: 2px;  
}

.popup_box {
  background-color: #222a42;
  position: fixed;
  width: 35%;
  top: 110px;
  left: 30%;
  margin: auto;
  border-radius: 8px;

}

.inputbox1 {
  width: 90%;
  padding: 12px;
  border: 2px solid #504377;
  margin: auto;
  background: transparent;
  margin-bottom: 10px;
  color: white;
  border-radius: 8px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}

.inputbox1:hover {
  background-color: #504377;
}

.inputbox1:active {
  background-color: gray;
}

.Text {
  font-weight: 800;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.1px;
  padding: 12px;
  color: aliceblue;
}

.submit {
  width: fit-content;
  padding: 12px;
  background-color: transparent;
  margin-top: 10px;
  border-color: #504377;
  justify-content: flex-end;
  color: rgb(255, 255, 255);
  letter-spacing: 0.5px;
}

.submit:hover {
  border: 2px solid #504377;
  background-color: #504377;
}

.Active {
  justify-content: space-between;
  margin-bottom: 5px;
}

.active{
  color: red;
}

.Role {
  margin-bottom: 5px;
}

.role1 {
  margin-left: 50px;
  color: aliceblue;
}

.active1 {
  color: aliceblue;

  margin-left: 29px;
}

.active2 {
  margin-left: 36px;
  color: aliceblue;
}

.head1 {
  width: 3%;
}

.head2 {
  width: 5%;
}

.head3 {
  width: 5%;
}

.head4 {
  width: 5%;
}

.body1 {
  width: 3%;
  font-size:16px;
}

.body2 {
  width: 18%;
  padding-right: 15px;
}

.body3 {
  width: 15%;
}

.body4 {
  width: 7%;
}

.actionButtons {
  display: flex;
  align-items: center;
}

.Tabs {
  display: flex;
}

.Tab {
  margin: 15px 30px;
}

.active {
  background: red;
}

.tabActive {
  background-color: #27335a;
}


.nav_tabs>li.active>a:after {
  position: absolute;
  content: " ";
  background: #27335a;
  width: 12px;
  height: 12px;
  border-radius: 3px 0 0 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  box-shadow: none;
  bottom: -6px;
  right: 50%;
}

.nav_tabs>li.active>a,
.nav_tabs>li.active>a:hover,
.nav_tabs>li.active>a:focus {
  color: black;
  cursor: pointer;
  background-color: transparent;
}

ul.nav_tabs {
  list-style: none;
  border: 0;
}

ul.nav_tabs li {
  display: inline-block;
}

ul.nav_tabs li.message-nav {
  float: right;
  margin-right: 20px;
}

ul.nav_tabs li.message-nav a {
  color: black;
  background-color: white;
}

.nav_tabs>li>a {
  position: relative;
  display: block;
}

.nav_tabs>li {
  position: relative;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  color: #d6e5f4;
}

.panel_body {
  padding: 30px 8px 0;
  background: #fff;
  align-items: center;
}

.cus_tab {
  color: #191919;
  padding-top: 10px;
  background: #FFF;
  
}

.cus_tab .panel_heading {
  background: #fff;
  display: flex;
  justify-content: space-between;
  
}


.cus_tab .nav_tabs>li>a {
  color: #191919;
  padding: 14px;
  border: 0;
  min-width: 130px;
  text-align: center;
  cursor: pointer;
  background-color: lightgrey;
  margin:2px;
  border:1px solid rgb(155, 154, 154);
}



.cus_tab .nav_tabs>li.active>a {
  background: #27335a;
  color: #fff;
}

.status_btn {
  font-size: 14px;
  white-space: nowrap;
  padding: 7px 26px;
  background: #009688;
  margin-left: 10px;
  color: #fff;
  border: 0;
  font-size: 15px;
  font-weight: 600;
  padding-left: 8px;
}


.status_btn_pwd {
  font-size: 14px;
  white-space: nowrap;
  padding: 7px 24px;
  background: #009688;
  margin-left: 10px;
  color: #fff;
  border: 0;
  font-size: 15px;
  font-weight: 600;
  background: #03a9f4;
  padding-left: 8px;
}

.edituser{
  display: flex;
  color:gray;
  font-size:15px;
}

.search{   
border: 2px solid #9aa0b5;
padding-bottom: 10px;
height: 52px;
float: right;
padding-left: 9px;
padding-top:5px;
margin-right: 10px;
}

.pagination{
  display: flex;
  justify-content: center;
  color:#141935;
}
.pagination a {
  color:#484851;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  border: 1px solid #484851;
  
}

.pagination a.active {
  background-color: #141935;
  color: rgb(53, 45, 45);
  border: 1px solid #ffffff;
}

.pagination a:hover:not(.active) {
  background-color: rgb(154, 162, 164);
  color:#d4d6e1;
}

.pagination a:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: #ffffff;
}

.pagination a:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #ffffff;
}

.buttonnew1{
  width: 25%;
  align-items:center;
}
.btn .btn-default{
  padding: 10px;
  justify-content: center;
  background-color: red;
}
.search1{
  border:none;
  outline: none;
  padding-left:5px;  
}
.mainnew1{
  display: flex;
  border:2px solid rgb(137, 130, 130);
  margin:auto;
  margin-right: 10px;
  height:52px;
}
.Textnewgender{
  width: 100%;
  padding: 14px; 
  margin: auto;
  background: transparent;
  margin-bottom: 10px;
  color: rgb(129, 121, 121);
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 24px;  
  border: 1px solid #a29999;
  box-shadow: 1px 1px #a29999;
}
.Textnew1{
  font-weight: 600;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.1px;
  padding: 10px;
  color: rgb(55, 57, 58);
}
.inputboxnew2{
  width: 100%;
  padding: 10px; 
  margin: auto;
  background: transparent;
  margin-bottom: 10px;
  color: rgb(129, 121, 121);
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 24px;  
  border: 1px solid #a29999;
  box-shadow: 1px 1px #a29999;
}
.submitnew{
  background-color: #27335a;
color: white;
padding: 9px 14px;
font-size: 13px;
}