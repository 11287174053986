.navlink {
  color: #666666 !important;
  text-decoration: none !important;
  margin: auto 50px !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  display: flex !important;
  align-items: center !important;
  white-space: nowrap;
}

.logo_img {
  // border: 3px solid #343456;
}



.ul {
  list-style: none;
  margin: 0px;
}

.a {
  text-decoration: none;
}

.menu_trigger img {
  // position: absolute;
  top: 20px;
  right: 20px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.dropdown_menu {
  position: absolute;
  top: 100px;
  right: 20px;
  // background-color: #141935;
  border-radius: 4px;
  // padding: 10px 20px;
  width: 200px;
}

.dropdown_menu::before {
  content: '';
  position: absolute;
  top: -5px;
  right: 20px;
  height: 20px;
  width: 20px;
  background: var(--secondary-bg);
  transform: rotate(45deg);
}

.dropdown_menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: var(--speed) ease;
  padding: 0;
  z-index: 111;
}

.dropdown_menu.inactive {
  display: none;
  opacity: 0;
  transform: translateY(0px);
  transition: var(--speed) ease;
}

h3 span {
  font-size: 14px;
  color: var(--secondary-text-color);
  font-weight: 400;
}

.dropdown_menu ul li {
  padding: 10px 0;
  list-style: none;
  // border-top: 1px solid black;

}

.dropdown_menu ul li:hover a {
  color: green;
  cursor: pointer;
}

.dropdown_menu ul li:hover img {
  opacity: 1;
  cursor: pointer;
}

.dropdownItem {
  display: flex;
  margin: 10px auto;
}

.dropdownItem img {
  max-width: 20px;
  margin-right: 10px;
  opacity: 0.5;
  transition: var(--speed);
}

.dropdownItem a {
  max-width: 100px;
  margin-left: 10px;
  transition: var(--speed);
}

.img {
  max-width: 20px;
  margin-right: 10px;
  opacity: 0.5;
  transition: var(--speed);
}

.link {
  color: #0161c3;
}

.link0 {
  background-color: #337297;
  width: 198px;
  position: relative;
  right: 39px;
  height: 40px;
}

.link1 {
  background-color: #235379;
  width: 198px;
  position: relative;
  right: 39px;
  height: 40px;
}

.link2 {
  background-color: #27335a;
  width: 198px;
  position: relative;
  right: 39px;
  height: 40px;
}

.link3 {
  background-color: #222a42;
  width: 198px;
  position: relative;
  right: 39px;
  height: 40px;
}

.NavLink1 {
  position: relative;
  left: 55px;
  top: 12px;
  color: white;
}

.NavLink2 {
  position: relative;
  left: 32px;
  top: 12px;
  color: white;
}

.NavLink3 {
  position: relative;
  left: 65px;
  top: 12px;
  color: white;
}

.pr16{
  padding-right: 16px;
}