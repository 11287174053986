* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.container {  

  display: flex;
  margin: 30px;
  min-height: 82vh;
  background: #fff;
}

main {
  width: 100%;
  padding: 15px;

}

.sidebar {
  // background-color:#222a42;
  // color: white;
  // margin: 20px;

  background-color: #ffff;
  padding: 13px;

}
a.link1{
  color:gray;  
}

a.active{
 color:#27335a;

}



// .top_section {
//   // display: flex;
//   // align-items: center;
//   // padding:20px 15px ;
// }

.bars {
  font-size: 25px;
  display: flex;
  margin-left: 20px;

}

.link {
  // display: flex;
  // color: white;
  // padding: 10px 15px;
  // gap: 15px;
  // transition: all 0.5s;

  display: flex;
  padding: 10px 15px;
  background: #27335a1c;
  margin-top: 15px;
  color: #191919;
}
.link1{
  display: flex;
  padding:10px 15px;
  background-color: transparent;
  color: black;
  font-weight: 600;

.active{
background-color:skyblue ;
color:#fff;
}
}

.link:hover {
  background: #27335a;
  color: rgb(252, 252, 252);
  transition: all 0.5s;

}

.link1:active {
  background: skyblue;
  color: grey;
}

// .link_text{
//   font-size: 20px;
//   background-color: #27335a;
//   padding: 5px;
// }

.admin_heading {
  color: #191919;
  margin-bottom: 14px;
}
@media screen and (min-width: 480px) and (max-width:600px) {
  .sidebar{
    display: none;
  }

}