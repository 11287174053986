.extract {
  cursor: pointer !important;
  span {
    text-decoration: underline !important;
  }

  a:-webkit-any-link {
    margin-top: 5px;
    font-size: 20px !important;
    line-height: normal !important;
    color: #d6c9ff !important;
  }
}

.table-wrapper {
  table {
    thead {
      margin: auto !important;
      width: 100% !important;
    }
    th {
      text-align: center !important;
      span {
        color: white !important;
        font-size: 14px !important;
        font-weight: bold !important;
        padding-left: 25px !important;
      }
    }
    tbody {
      max-height: 740px !important;
      width: 100% !important;
      margin: auto !important;        
      &::-webkit-scrollbar {
        width: 8px !important;
        background-color: #141935 !important;
        border-radius: 30px !important;
        margin-left: 12px !important;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 30px !important;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
        background-color: #343456 !important;
        margin-left: 12px !important;
      }
      td {
        text-align: center !important;
       }
    }
  }
}

.scrollUp {
  position: relative;

  div {
    padding: 6px;
    position: absolute;
    right: 48px;
  }
}
