.middle {
  position: absolute;
  top: 70%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
}
.btnFooter {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: #f1f1f1;
  margin: 10px;
  border-radius: 50%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25), 0 5px 10px rgba(0, 0, 0, 0.22);
  color: #446eac;
  overflow: hidden;
  position: relative;
}
.btnFooter i {
  line-height: 40px;
  font-size: 20px;
  transition: 0.2s linear;
}
.btnFooter:hover i {
  transform: scale(1.3);
  color: #f1f1f1;
}
.btnFooter::before {
  content: '';
  position: absolute;
  width: 120%;
  height: 120%;
  background: #446eac;
  transform: rotate(45deg);
  left: -110%;
  top: 90%;
}
.btnFooter:hover::before {
  animation: aaa 0.7s 1;
  top: -10%;
  left: -10%;
}
@keyframes aaa {
  0% {
    left: -110%;
    top: 90%;
  }
  50% {
    left: 10%;
    top: -30%;
  }
  100% {
    top: -10%;
    left: -10%;
  }
}
.empty-height{
  height: 250px;
}